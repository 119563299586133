<template>

  <div>

    <!-- Hero section Institucional -->
    <section
      v-if="topoInternas_bg !=''"
      class="hero-section hero-section-inst set-img d-flex align-items-end"
      :style="{ backgroundImage: 'url(' + topoInternas_bg + ')' }"
    >
      <div class="container">
        <div class="row">
          <div class="col text-center m_bottom-50">
            <h2>{{ topoInternasTitulo }}</h2>
          </div>
        </div>
      </div>
    </section> 


    <section
      v-else
      class="hero-section hero-section-inst set-img d-flex align-items-end"
      style="background-color: #ccc;"
    >
      <div class="container">
        <div class="row">
          <div class="col text-center m_bottom-50">
            <h2>{{ topoInternasTitulo }}</h2>
          </div>
        </div>
      </div>
    </section>    
    <!------------------------- Fim Header Institucional -->


    <!-- Sobre -->
    <section class="spad-100">
      <div class="container">
        <img class="mb-5" :src="sobreImg" alt="">
        <div class="row about-text">
          <div class="col-xl-6 about-text-left mb-5 mb-xl-0">
            <h5>{{ sobreTituloEsq }}</h5>
            <p v-html="sobreDescricaoEsq"></p>
          </div>
          <div class="col-xl-6 about-text-right">
            <h5>{{ sobreTituloDir }}</h5>
            <p v-html="sobreDescricaoDir"></p>
          </div>
        </div>
      </div>
    </section>

    <!-- Depoimentos -->
    <Depoimentos />

    <!-- Galeria -->
    <section v-if="nossaEstruturaTitulo!=''" class="feature-category-section spad bg-white border-gradient-bottom">     
      <div class="container">
        <div class="section-title text-center">
          <h3 style="font-weight: bold;">{{ nossaEstruturaTitulo }}</h3>
        </div>
        <div class="row justify-content-center">
          <div 
            class="col-6 col-md-3 spacement"
            v-for="imagem in nossaEstrutura"
            :key="imagem.id"
          >
            <img 
              v-if="imagem.institucionalImg !=''"
              class="w-100" 
              :src="$apiUrlIndex + imagem.institucionalImg[0].urlImagem"
            >
            <img 
              v-else
              src="/img/indisponivel.jpg" 
              alt="Galeria de imagens"
            >
          </div>
        </div>

      </div>
    </section>
    

  </div>
</template>

<script>

import Depoimentos from '../shared/Depoimentos.vue'

export default {
  components: {
    Depoimentos
  },
  data() {
    return {

      topoInternas: [],
      topoInternasTitulo: '',
      topoInternas_bg: '',

      sobre: [],
      sobreImg: '',
      sobreTituloEsq: '',
      sobreDescricaoEsq: '',
      sobreTituloDir: '',
      sobreDescricaoDir: '',

      nossaEstrutura: [],
      nossaEstruturaTitulo: ''

    }
  },
  created() {

    this.$http
    .get(this.$apiUrl + "/institucional/telas/sobre%20topo")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.topoInternas = retornoAPI

      this.topoInternasTitulo = this.topoInternas[0].nome
      this.topoInternas_bg = this.$apiUrlIndex + this.topoInternas[0].institucionalImg[0].urlImagem

    });

    
    this.$http
    .get(this.$apiUrl + "/institucional/telas/sobre%20descricao")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.sobre = retornoAPI

      this.sobreTituloEsq = this.sobre[0].nome
      this.sobreDescricaoEsq = this.sobre[0].descricao1
      this.sobreTituloDir = this.sobre[0].campoExtra1
      this.sobreDescricaoDir = this.sobre[0].descricao2
      this.sobreImg = this.$apiUrlIndex + this.sobre[0].institucionalImg[0].urlImagem

    });


    this.$http
    .get(this.$apiUrl + "/institucional/telas/nossa%20estrutura")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.nossaEstrutura = retornoAPI
     

      this.nossaEstruturaTitulo = this.nossaEstrutura[0].nome

    });

  }
  
}

</script>

