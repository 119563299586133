<template>
  <div>
    <!-- Header section -->
    <header 
      class="header-section border-gradient-bottom"
      id="navbar"
      style="background-color: #b30225;"
    >
      <div class="container">
        <b-navbar toggleable="lg" class="ftco_navbar ftco-navbar-light">
          <b-navbar-brand>
            <router-link to="/" class="navbar-brand">
              <img class="logo-topo" src="/img/01.png" alt="Logo" />
            </router-link>
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"><i class="fa fa-bars text-white"></i></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="navbar-nav menu-xl ml-auto">
              <b-nav-item exact to="/">Home</b-nav-item>
              <b-nav-item exact to="/sobre">Sobre</b-nav-item>
              <a 
                class="nav-link"
                href="#"
                @click="$redirect_reload('/imoveis/1/Aluguel')"
              >
                Aluguel
              </a>
              <a 
                class="nav-link"
                href="#"
                @click="$redirect_reload('/imoveis/2/Compra')"
              >
                Compra
              </a>
              <a 
                class="nav-link"
                href="#"
                @click="$redirect_reload('/imoveis/3/Investimento')"
              >
                Imóveis para investimento
              </a>
            <!--   <b-nav-item exact to="/servicos">Serviços</b-nav-item> -->
              <b-nav-item to="/contato">Contato</b-nav-item>
              <a target="_blank"
                :href="headerWhatsappLink" 
                class="nav-link btn-top border-gradient px-4 mt-4 mt-lg-0"
              >
                <i class="fa fa-whatsapp m-right-5"></i> 
                {{ headerWhatsapp }}
              </a>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </header>
    <!-- Header section end -->
  
  </div>
</template>

<script>
// import FiltraImoveis from './FiltraImoveis.vue'


// ------ Efeito slide down menu superior
var prevScrollpos = window.pageYOffset;
window.onscroll = function() {
  var currentScrollPos = window.pageYOffset;
  if (prevScrollpos > currentScrollPos) {
    document.getElementById("navbar").style.top = "0";
  } else {
    document.getElementById("navbar").style.top = "-100px";
  }
  prevScrollpos = currentScrollPos;
}
// ----- 

export default {
  props: ['tituloTopo'],
  components: {
    // FiltraImoveis
  },
  data() {
    return {
      selectedTipo: 'Tipo de Imóvel',
      selectedCidade: 'Tipo de Cidade',
      selectedBairro: 'Tipo de Bairro',
      selectedMin: 'De: R$',
      selectedMax: 'Até: R$',
       options: [
        'foo',
        'bar',
        'baz'
      ],

      // Header
      header: [],
      headerWhatsapp: '',
      headerWhatsappLink: '',

    }
  },
  created() {

    this.$http
    .get(this.$apiUrl + "/institucional/telas/topo")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.header = retornoAPI

      this.headerWhatsapp = this.header[0].nome
      this.headerWhatsappLink = this.header[0].campoExtra1
    });


  }

}
</script>

<style scoped>

  @import '/css/header.css';

</style>