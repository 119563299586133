import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/components/site/views/Home.vue'
import Imoveis from '@/components/site/views/Imoveis.vue'
import ImovelDetalhe from '@/components/site/views/ImovelDetalhe.vue'
import Servicos from '@/components/site/views/Servicos.vue'
import Sobre from '@/components/site/views/Sobre.vue'
import Blog from '@/components/site/views/Blog.vue'
import BlogDetalhe from '@/components/site/views/BlogDetalhe.vue'
import Contato from '@/components/site/views/Contato.vue'


Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if(savedPosition) {
          return resolve(savedPosition)
        }
        if(to.hash) {
          return resolve({
            selector: to.hash,
            offset: {x: 0, y: 0}
          })
        }
        return resolve({ 
          x: 0, y: 0,
          behavior: 'smooth'
        })
      }, 700) 
    })
  },
  routes: [
    {path: '/', component: Home},
    {path: '/imoveis/:idSegmento/:nome', component: Imoveis},
    {path: '/imoveis/:idSegmento2/:nome', component: Imoveis},
    {path: '/imovelDetalhe/:nome/:idImovel', component: ImovelDetalhe},
    {path: '/servicos', component: Servicos},
    {path: '/servicos/:titulo/:idServico', component: Servicos},
    {path: '/sobre', component: Sobre},
    {path: '/blog', component: Blog},
    {path: '/blogDetalhe/:nome/:idNoticia', component: BlogDetalhe},
    {path: '/contato', component: Contato},

  ]
})