<template>
  
  <section 
    class="cta set-img spad-100"
    :style="{ backgroundImage: 'url(' + cta_bg + ')' }"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10 col-lg-7 col-xl-6 flex-center">
          <div class="cta-content">
            <!-- <h2>Fale agora <span>mesmo</span> com nossa equipe</h2> -->
            <p v-html="ctaTitulo" style="color:#ffffff !important;"></p>
          </div>
        </div>
        <div class="col-lg-5 col-xl-6 flex-center mt-5 mt-lg-0">
          <div class="cta-content">
            <a :href="ctaBtnLink" class="btn-cta">{{ ctaBtn }}</a>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
export default {
  data() {
    return {

      cta: [],
      ctaTitulo: '',
      ctaBtn: '',
      ctaBtnLink: '',
      cta_bg: ''

    }
  },
  created() {

    this.$http
    .get(this.$apiUrl + "/institucional/telas/fale%20conosco")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.cta = retornoAPI

      this.ctaTitulo = this.cta[0].descricao1
      this.ctaBtn = this.cta[0].textoBtn1
      this.ctaBtnLink = this.cta[0].linkBtn1
      this.cta_bg = this.$apiUrlIndex + this.cta[0].institucionalImg[0].urlImagem

    });

  }
}
</script>

<style>

  @import '/css/cta.css';

  .cta-content * {
    color:#ffffff;
  }

</style>