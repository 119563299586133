<template>

  <div>

    <!-- Hero section Institucional -->
    <section
      v-if="topoInternas_bg !=''"
      class="hero-section hero-section-inst set-img d-flex align-items-end"
      :style="{ backgroundImage: 'url(' + topoInternas_bg + ')' }"
    >
      <div class="container">
        <div class="row">
          <div class="col text-center m_bottom-50">
            <h2>{{ topoInternasTitulo }}</h2>
          </div>
        </div>
      </div>
    </section> 


    <section
      v-else
      class="hero-section hero-section-inst set-img d-flex align-items-end"
      style="background-color: #ccc;"
    >
      <div class="container">
        <div class="row">
          <div class="col text-center m_bottom-50">
            <h2>{{ topoInternasTitulo }}</h2>
          </div>
        </div>
      </div>
    </section>    
    <!------------------------- Fim Header Institucional -->


    <!-- Contato -->
    <section class="spad-100 blog-page border-gradient-bottom">
      <div class="container">

        
        <div class="row">
          <div class="col-lg-5 footer-widget">
            <div class="contact-widget">
              <div class="contato-item d-flex align-items-center mb-2">
                <i class="fa fa-map-marker m-right-10"></i>
                <p v-html="contatoEndereco"></p>
              </div>
              <div class="contato-item d-flex align-items-center mb-2">
                <i class="fa fa-phone m-right-10"></i>
                <p v-html="contatoTelefone"></p>
              </div>
              <div class="contato-item d-flex align-items-center mb-2">
                <i class="fa fa-envelope m-right-10"></i>
                <p v-html="contatoEmail"></p>
              </div>
            </div>
          </div>
          <div class="col-lg-7">
            <div class="contact-right" style="margin-bottom: 100px;">
              <div class="section-title">
                <h3>{{ contatoTitulo }}</h3>
                <p v-html="contatoDescricao"></p>
              </div>
              <form class="contact-form">
                <div class="row">
                  <!-- <div class="col-md-12">
                    <input type="text" placeholder="Nome">
                  </div> -->
                  <div class="col-md-12">
                    <input 
                      type="text" 
                      placeholder="Nome"
                      v-model="nome"
                    >
                  </div>
                  <div class="col-md-6">
                    <input 
                      type="email"
                      placeholder="E-mail"
                      v-model="email"
                    >
                  </div>
                  <div class="col-md-6">
                    <input 
                      type="text" 
                      placeholder="Telefone"
                      v-model="Contato.Assunto"
                    >
                  </div>
                  <div class="col-md-12">
                    <textarea  
                      placeholder="Mensagem"
                      v-model="mensagem"
                    >
                    </textarea>
                    <button 
                      type="button"
                      class="btn-contact"
                      @click="enviarDadosFormulario"
                    >
                      Enviar contato
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <!-- Mapa -->
        <!-- <div class="row">
          <div class="col">
            <div class="map m_bottom-100">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d220896.0095882688!2d-51.31722838671698!3d-30.108498709504843!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95199cd2566acb1d%3A0x603111a89f87e91f!2sPorto%20Alegre%2C%20RS!5e0!3m2!1spt-BR!2sbr!4v1586199373582!5m2!1spt-BR!2sbr" width="100%" height="450px" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            </div>
          </div>
        </div> -->
        
        
      </div>
    </section>

  </div>
</template>

<script>

// import Depoimentos from '../shared/Depoimentos.vue'

export default {
  components: {

  },
  data() {
    return {

      topoInternas: [],
      topoInternasTitulo: '',
      topoInternas_bg: '',

      contato: [],
      contatoTitulo: '',
      contatoDescricao: '',
      contatoEndereco: '',
      contatoTelefone: '',
      contatoEmail: '',
      contatoEmailRecebe:'',

      nome: '',
			email: '',
			mensagem: '',


      // Objeto Contato      
      Contato: {
        NomeEmpresa: "BRX Imobiliária",
        Assunto: "",
        Destinatario: "",
        EmailResposta: "",
        TextoEmail: ""
      }

    }
  },
  created() {

    this.$http
    .get(this.$apiUrl + "/institucional/telas/contato%20topo")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.topoInternas = retornoAPI

      this.topoInternasTitulo = this.topoInternas[0].nome
      this.topoInternas_bg = this.$apiUrlIndex + this.topoInternas[0].institucionalImg[0].urlImagem

    });



    this.$http
    .get(this.$apiUrl + "/institucional/telas/info%20contato")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.contato = retornoAPI

      this.contatoTitulo = this.contato[0].nome
      this.contatoDescricao = this.contato[0].descricao1
      this.contatoTelefone = this.contato[0].campoExtra2
      this.contatoEmail = this.contato[0].campoExtra1
      this.contatoEndereco = this.contato[0].descricao3
       this.contatoEmailRecebe = this.contato[0].referencia

    });

  },
  methods: {
    enviarDadosFormulario() {

      //Email que irá receber os dados do formulário preenchido (empresa site)
      // this.Contato.Destinatario = this.contatoEmail
      this.Contato.Destinatario = this.contatoEmailRecebe

      //Email de quem preencheu formulário e enviou email (usuário/cliente)
      this.Contato.EmailResposta = this.email

      // Dados que aparecerão no corpo do email
      this.Contato.TextoEmail = 
        "Nome: " + this.nome + 
        " <br /> Email: " + this.email +
        " <br /> Telefone: " + this.Contato.Assunto +
        " <br /> Descrição: " + this.mensagem

      //validar vazios
      if(this.nome == '' || this.email == '' || this.Contato.Assunto == '' || this.mensagem == '') {
        this.$mensagem_normal("Preencha todos os campos");
      
      }else {

        //SE NAO TIVER ANEXO:
        console.log(this.Contato)

        this.$http.post(this.$apiUrl +"/contato/enviamail", this.Contato)
        .then(response => {
          if(response.ok)
            this.$mensagem_normal("Enviado com sucesso!")
            this.nome = ''
            this.email = ''
            this.mensagem = ''
            this.Contato.Assunto=""
        },error => {
          this.$mensagem_normal(error.bodyText)
          this.nome = ''
          this.email = ''
          this.mensagem = ''
          this.Contato.Assunto=""
        });

      }
     
    }
  }
  
}

</script>

