<template>

  <div>

    <!-- Hero section Internas -->
    <section
      v-if="topoInternas_bg !=''"
      class="hero-section hero-section-in set-img d-flex align-items-end"
      :style="{ backgroundImage: 'url(' + topoInternas_bg + ')' }"
    >
      <div class="container mt-4">
        <div class="row">
          <div class="col text-center">
            <h2>{{ modalidade }}</h2>
          </div>
        </div>
        <div class="row">
          <!-- Filter  -->
          <FiltraImoveis />
        </div>
      </div>
    </section>  


    <!-- v-else -->
    <section
      v-else
      class="hero-section hero-section-in set-img d-flex align-items-end mt-4"
      style="background-color: #ccc;"
    >
      <div class="container">
        <div class="row">
          <div class="col text-center">
            <h2>{{ modalidade }}</h2>
          </div>
        </div>
        <div class="row">
          <!-- Filter  -->
          <FiltraImoveis />
        </div>
      </div>
    </section>  
    <!------------------------- Fim Header Imóveis -->


    <!--  -->
    <section class="feature-section spad">
      <div class="container">
        <div class="section-title">
          <h3 style="font-weight: bold;">Imóveis para {{ modalidade }}</h3>
        </div>
        <div class="row justify-content-center">
          <div 
            class="col-lg-4 col-md-6"
            v-for="imovel in imoveisPorModalidade"
            :key="imovel.id"
          >
            <!-- feature -->
            <div class="feature-item">
              <router-link :to="/imovelDetalhe/  + 
              imovel.nome.replaceAll(' ','-').replaceAll('/','').replaceAll('#','') + 
              '/' + imovel.institucionalId">
                <div 
                  v-if="imovel.institucionalImg !=null"
                  class="feature-pic set-bg"
                  :style="{ backgroundImage: 'url(' + imovel.institucionalImg[0].urlImagem + ')' }" 
                >
                </div>
                <div v-else class="border">
                  <img src="/img/indisponivel.jpg">               
                </div>                                 
                <!-- <div class="sale-notic">Venda</div> -->
              </router-link>
              <div class="feature-text">
                <div class="text-center feature-title">                  
                  <span class="price">{{ imovel.cidade.nomeCidade }}/{{ imovel.cidade.estado.siglaEstado }} - {{ imovel.bairro }}</span>
                  <router-link :to="/imovelDetalhe/  + 
              imovel.nome.replaceAll(' ','-').replaceAll('/','').replaceAll('#','') + 
              '/' + imovel.institucionalId">
                    <h4 style="font-size: 20px;min-height: 70px">{{ imovel.nome }}</h4>
                  </router-link>
                  <h6 class="price">{{ imovel.textoBtn2 }}</h6>                 
                </div>
                <router-link 
                  :to="/imovelDetalhe/  + 
              imovel.nome.replaceAll(' ','-').replaceAll('/','').replaceAll('#','') + 
              '/' + imovel.institucionalId"
                  class="room-price"
                >
                  <div class="feature-footer">
                    <p v-if="imovel.campoExtra1 !=''"><i class="fa fa-th-large m-right-5"></i>{{ imovel.campoExtra1 }}</p>
                    <p v-if="imovel.campoExtra2 !=''"><i class="fa fa-bed m-right-5"></i>{{ imovel.campoExtra2 }}</p>
                    <p v-if="imovel.campoExtra3 !=''"><i class="fa fa-shower m-right-5"></i>{{ imovel.campoExtra3 }}</p>
                  </div>
                </router-link>
              </div>
            </div>
          </div>           
        </div>
      </div>
    </section>


    <!-- CTA -->
    <CTA />

  </div>
</template>

<script>
import FiltraImoveis from '../shared/FiltraImoveis.vue'
import CTA from '../shared/CTA.vue'




export default {
  components: {
    FiltraImoveis,
    CTA
  },
  data() {
    return {

      topoInternas: [],
      topoInternas_bg: '',

      imoveisPorModalidade: [],
      idModalidade: this.$route.params.idSegmento1,
      modalidade: this.$route.params.nome,
            // Titulo no topo
      NomeSeg: this.$route.params.nome,
      // Imóveis por modalidade (compra, invest)
      idSeg: this.$route.params.idSegmento, 
      // Imóveis por tipo (casa, ap)    
      idImovelTipo: this.$route.params.idSegmento2,
      
      imoveisLista: [],
      imgteste:'',
    }
  },
  created() {
    console.log('this.topoInternas_bg ')
    this.$http
    .get(this.$apiUrl + "/institucional/telas/topo%20imoveis")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.topoInternas = retornoAPI

      if (this.topoInternas[0].institucionalImg!= ''){
         this.topoInternas_bg = this.$apiUrlIndex + this.topoInternas[0].institucionalImg[0].urlImagem
      }
     

    });

   /*  this.$http
    .get(this.$apiUrl + "/institucional/telas/cad%20imoveis")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.imoveisPorModalidade = retornoAPI.filter((x) => x.visivel && x.segmentoInstNivel1.segmentoInstNivel1Id == this.idModalidade)
      
    });
 */

 
    //verificar se filtro imóvel foi disparado (localstorage)
    let dadosArmazenados = ''
    dadosArmazenados = localStorage.getItem('dadosBuscaBRXImobi');
    if (dadosArmazenados) {
      dadosArmazenados = JSON.parse(dadosArmazenados);
      this.BuscaDados(dadosArmazenados)
    } 
    else{

      this.$http
      .get(this.$apiUrl + "/institucional/telas/cad%20imoveis")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.imoveisPorModalidade = retornoAPI.filter((x) => x.visivel && x.segmentoInstNivel1!=null && x.segmentoInstNivel1.segmentoInstNivel1Id == this.idSeg).reverse()

        this.imoveisPorModalidade.forEach(element => {
          element.institucionalImg.forEach(element2 => {
            element2.urlImagem = this.$apiUrlIndex + element2.urlImagem.replace(/ /g, '%20')
          });
        });
        console.log(this.imoveisPorModalidade)
      });

      
  }
  
},
 methods: {           
              
  BuscaDados(Value) {
      this.$http
      .get(this.$apiUrl + "/institucional/telas/cad%20imoveis")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.imoveisPorModalidade = retornoAPI
        console.log("Lista Geral")
        console.log(this.imoveisPorModalidade)

         console.log("filtro")
        console.log(Value[0])
       
         if (Value[0].codigo != null){
              this.imoveisPorModalidade = this.imoveisPorModalidade.filter(x => x.referencia.replaceAll('Cód.:','').trim() == Value[0].codigo)
          }
          else{  
            
                let vvalorde = Value[0].ValorDe;
                let vvalorate = 0;

                if (Value[0].ValorAte == "0,00"){
                      vvalorate = "999.999.999.99"
                }
                else{
                    vvalorate = Value[0].ValorAte
                }

                
                  // filtra os imoveis com tipo, valorde e ate e estado
                  if (Value[0].Estado != null){
                      this.imoveisPorModalidade = this.imoveisPorModalidade.filter(x => x.segmentoInstNivel1!=null && x.segmentoInstNivel1.segmentoInstNivel1Id == Value[0].TipoNegocio 
                      && parseFloat(x.textoBtn2.replaceAll('R$','').replaceAll('.','').replaceAll(',','.')) >= parseFloat(vvalorde.replaceAll('.','').replaceAll(',','.')) 
                      && parseFloat(x.textoBtn2.replaceAll('R$','').replaceAll('.','').replaceAll(',','.')) <= parseFloat(vvalorate.replaceAll('.','').replaceAll(',','.')) 
                      && x.estado.estadoId == Value[0].Estado.estadoId)
                      console.log("estado")
                        console.log(this.imoveisPorModalidade)
                        // filtra os imoveis com tipo, valorde e ate e estado e cidade
                        if (Value[0].Cidade != null)
                        {
                            this.imoveisPorModalidade = this.imoveisPorModalidade.filter(x => x.segmentoInstNivel1!=null && x.segmentoInstNivel1.segmentoInstNivel1Id == Value[0].TipoNegocio 
                            && parseFloat(x.textoBtn2.replaceAll('R$','').replaceAll('.','').replaceAll(',','.')) >= parseFloat(vvalorde.replaceAll('.','').replaceAll(',','.')) 
                            && parseFloat(x.textoBtn2.replaceAll('R$','').replaceAll('.','').replaceAll(',','.')) <= parseFloat(vvalorate.replaceAll('.','').replaceAll(',','.')) 
                            && x.estado.estadoId == Value[0].Estado.estadoId 
                            && x.cidade.cidadeId == Value[0].Cidade.cidadeId)
                             console.log("Cidade")
                            / console.log(this.imoveisPorModalidade)
                            // filtra os imoveis com tipo, valorde e ate e estado e cidade e bairro
                            if (Value[0].Bairro != null)
                            {
                            this.imoveisPorModalidade =  this.imoveisPorModalidade.filter(x => x.segmentoInstNivel1!=null && x.segmentoInstNivel1.segmentoInstNivel1Id == Value[0].TipoNegocio 
                            && parseFloat(x.textoBtn2.replaceAll('R$','').replaceAll('.','').replaceAll(',','.')) >= parseFloat(vvalorde.replaceAll('.','').replaceAll(',','.')) 
                            && parseFloat(x.textoBtn2.replaceAll('R$','').replaceAll('.','').replaceAll(',','.')) <= parseFloat(vvalorate.replaceAll('.','').replaceAll(',','.')) 
                            && x.estado != null && x.estado.estadoId == Value[0].Estado.estadoId 
                            && x.cidade != null && x.cidade.cidadeId == Value[0].Cidade.cidadeId
                            && x.bairro != null && x.bairro.toLowerCase() == Value[0].Bairro.toLowerCase())

                             console.log("Bairro")
                             console.log(this.imoveisPorModalidade)
                            }
                        }
                        //console.log('aqui')
                        //console.log(this.imoveisLista)
                        //console.log(Value[0].TipoImovel)
                        if (Value[0].TipoImovel != 0){
                          //console.log('aqui2')
                          this.imoveisPorModalidade =  this.imoveisPorModalidade.filter(x => x.segmentoInstNivel1!=null && x.segmentoInstNivel1.segmentoInstNivel1Id == Value[0].TipoNegocio && x.segmentoInstNivel2 != null
                          && x.segmentoInstNivel2.segmentoInstNivel2Id == Value[0].TipoImovel)

                          console.log("tipo imovel")
                          console.log(this.imoveisPorModalidade)
                        }
                  }
                  else{
                     if (Value[0].TipoImovel != 0){
                          this.imoveisPorModalidade =  this.imoveisPorModalidade.filter(x => x.segmentoInstNivel1!=null && x.segmentoInstNivel1.segmentoInstNivel1Id == Value[0].TipoNegocio && x.segmentoInstNivel2 != null
                          && x.segmentoInstNivel2.segmentoInstNivel2Id == Value[0].TipoImovel)
                        }
                    // filtra os imoveis com tipo, valorde e ate
                      this.imoveisPorModalidade = this.imoveisPorModalidade.filter(x => x.segmentoInstNivel1!=null && x.segmentoInstNivel1.segmentoInstNivel1Id == Value[0].TipoNegocio 
                      && parseFloat(x.textoBtn2.replaceAll('R$','').replaceAll('.','').replaceAll(',','.')) >= parseFloat(vvalorde.replaceAll('.','').replaceAll(',','.')) 
                      && parseFloat(x.textoBtn2.replaceAll('R$','').replaceAll('.','').replaceAll(',','.')) <= parseFloat(vvalorate.replaceAll('.','').replaceAll(',','.')))
                       
                       console.log("tipo imovel 2")
                       console.log(this.imoveisPorModalidade)
                     
                  }
             }   

        localStorage.removeItem('dadosBuscaBRXImobi');

        if (this.imoveisPorModalidade==''){
           this.$mensagem_normal("Não foram encontrados imóveis para esta pesquisa.");
        }
                      
      });
    },
 }
}

</script>

