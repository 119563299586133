<template>
  <div id="app">

    <div v-html="codigo_SEO"></div>

    <Header />

    <transition name="fadeIn">
      <router-view></router-view>
    </transition>

    <Footer />

  </div>
</template>

<script>

  import Header from './components/site/shared/Header.vue'
  import Footer from './components/site/shared/Footer.vue'

  export default {
    name: 'App',
    components: {
      Header,
      Footer
    },
    data() {
      return {

        seobody: [],
        codigo_SEO: '',


        seoHead:[],
        codigoHead: '',


        seoAnalitics:[],
        codigoAnalitics: '',


        metaHome: [],
        codigoHome: ''
        
      }
    },
    created() {

      this.$http
      .get(this.$apiUrl + "/seobody/")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.seobody = retornoAPI
        this.codigo_SEO = this.seobody[0].codigo
      });


      this.$http
      .get(this.$apiUrl + "/seohead/")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.seoHead = retornoAPI;
        this.codigoHead = this.seoHead[0].codigo;
      });


      this.$http
      .get(this.$apiUrl + "/seoanalitycs/")
      .then((res) => res.json())
      .then((idi) => {
        this.seoAnalitics = idi;
        this.codigoAnalitics = this.seoAnalitics[0].codigo;
      });


      this.$http
      .get(this.$apiUrl + "/Seometahome/")
      .then((res) => res.json())
      .then((idi) => {
        this.metaHome = idi;
        this.codigoHome = this.metaHome[0].codigo;
        document.head.innerHTML += this.codigoHead.trim('"') + this.codigoAnalitics.trim('"') + this.codigoHome.trim('"');
      });
    }
  }
</script>

<style>

  /* css tmp */
  @import '/css/style.css';
  @import '/css/default.css';
  @import '/css/carousel.css';
  @import '/css/depoimentos.css';
  @import '/css/avanceDigital.css';


  /*---------- Animations */
  .fadeIn-enter {
    opacity: 0;
  }
  .fadeIn-enter-active {
    transition: opacity .8s;
  }
  .fadeIn-enter-to {
  opacity: 1;
  }

   .ql-size-large{
    font-size: 25px;
  }

  .ql-size-huge{
    font-size: 45px;
  }

  .ql-size-small{
     font-size: 12px;
  }

  .ql-align-justify{
    text-align: justify;
  }
@media (max-width:767px) {
    .hidden-xs {
        display: none !important;
    }
        
    .alturaimg{
      height: 30px;
      padding-right: 1px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .hidden-sm {
        display: none !important;
    }

    .alturaimg{
      height: 40px;
      padding-right: 1px;
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .hidden-md {
        display: none !important;
    }
    
    .alturaimg{
      height: 60px;
      padding-right: 2px;
    }
}

@media (min-width:1200px) {
    .hidden-lg {
        display: none !important;
    }

    .alturaimg{
      height: 60px;
      padding-right: 2px;
    }
}

p {
    margin-top: 0;
    margin-bottom: 0rem !important;
}

</style>
