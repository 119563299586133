<template>
  
  <!-- Depoimentos -->
  <!-- <section 
    class="depoimentos section_pad-50 hidden-sm"
  >
    <div class="container">
      <div class="row">
        <div class="col">
          <h6 class="text-dark border-bottom">Depoimentos</h6>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div>
            <b-carousel
              id="carousel-1"
              :interval="5000"
              controls
              indicators
              img-width="1024"
              img-height="325"
            >

              <b-carousel-slide 
                img-blank img-alt="Blank image"
                v-for="depoimento in depoimentos" :key="depoimento.id"
              >
                <div>
                  <div class="row justify-content-center">
                    <div class="col-lg-9 depo-content">
                      <p 
                        class="depoimento-texto text-center mb-2 mb-xl-4"
                        v-html="depoimento.descricao1"
                      >                          
                      </p>
                      <div class="depo-autor flex-center my-xl-5">
                        <img class="m-right-10" :src="$apiUrlIndex + depoimentos[0].institucionalImg[0].urlImagem">
                        <h6 class="text-dark mb-0">{{ depoimento.nome }}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </b-carousel-slide>

            </b-carousel>

          </div>
        </div>
      </div>
    </div>
  </section> -->
  
  <section 
    v-if="depoimentos.length > 0"
    class="depoimentos set-img section_pad-50 hidden-sm"
    :style="{ backgroundImage: 'url(' + depoimentosSection_bg + ')' }"
  >
    <div class="container">
      
      <div class="row">
        <div class="col">
          <div>
            <b-carousel
              id="carousel-1"
              :interval="5000"
              controls
              indicators
              img-width="1024"
              img-height="350"
            >

              <!-- 1 -->
              <b-carousel-slide 
                v-for="depoimento in depoimentos"
                :key="depoimento.id"
                img-blank img-alt="Blank image"
              >
                <div>
                  <div class="row justify-content-center">
                    <div class="col-12">
                      <div class="rating hidden-md hidden-lg">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                      </div>
                      <p 
                        class="depoimento-texto text-center mb-2 mb-xl-4"
                        v-html="depoimento.descricao1"
                      >                                               
                      </p>
                      <div class="depoimento-autor text-center my-xl-5">
                        <div>
                          <h6 class="text-white mb-0">{{ depoimento.nome }}</h6>
                          <p class="text-white-50">{{ depoimento.campoExtra1 }}</p>
                        </div>
                        <img 
                          v-if="depoimento.institucionalImg !=''"
                          :src="$apiUrlIndex + depoimento.institucionalImg[0].urlImagem"
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </b-carousel-slide>

            </b-carousel>

          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
export default {
  data() {
    return {

      depoimentosSection: [],
      depoimentosSection_bg: '',
      depoimentos: []

    }
  },
  created() {

    this.$http
    .get(this.$apiUrl + "/institucional/telas/depoimentos%20fundo")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.depoimentosSection = retornoAPI

      this.depoimentosSection_bg = this.$apiUrlIndex + this.depoimentosSection[0].institucionalImg[0].urlImagem

    });


    this.$http
    .get(this.$apiUrl + "/institucional/telas/depoimentos")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.depoimentos = retornoAPI.filter((x) => x.visivel)

      this.depoimentos.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }       
        if (a.ordem < b.ordem) { return -1; }        
        return 0;
      })


    });

  }
}
</script>

<style scoped>

  

</style>