<template>
  
  <!-- Footer -->
  <footer 
    class="footer-section set-img"
    :style="{ backgroundImage: 'url(' + footer_bg + ')' }"
  >
    <div class="container">
      <div class="row">
        
      </div>
      <div class="row">
        <!-- Logo -->
        <div class="col-md-6 col-lg-6 col-xl-2 footer-widget"> 
          <router-link to="/"><img src="/img/12.png" alt="Logo"></router-link>
          <div class="social">
            <a v-if="facebookLink!=''" :href="facebookLink" target="_blank"><i class="fa fa-facebook"></i></a>
            <a v-if="instagramLink!=''" :href="instagramLink" target="_blank"><i class="fa fa-instagram"></i></a>
            <a v-if="youtubeLink!=''" :href="youtubeLink" target="_blank"><i class="fa fa-youtube"></i></a>
            <a v-if="twitterLink!=''" :href="twitterLink" target="_blank"><i class="fa fa-twitter"></i></a>
          </div>
        </div>
        <!-- Contatos -->
        <div class="col-md-6 col-lg-6 col-xl-3 col-md-6 footer-widget">
          <div class="contact-widget">
            <div class="contato-item d-flex align-items-center mb-2">
              <i class="fa fa-map-marker m-right-10"></i>
              <p v-html="contatoEndereco"></p>
            </div>
            <div class="contato-item d-flex align-items-center mb-2">
              <i class="fa fa-phone m-right-10"></i>
              <p v-html="contatoTelefone"></p>
            </div>
            <div class="contato-item d-flex align-items-center mb-2">
              <i class="fa fa-envelope m-right-10"></i>
              <p v-html="contatoEmail"></p>
            </div>
          </div>
        </div>
        <!-- Menu -->
        <div class="col-md-6 col-lg-6 col-xl-4 footer-widget mt-5 mt-xl-0">
          <div class="double-menu-widget d-flex pl-lg">
            <ul>
              <li><router-link to="/">Home</router-link></li>
              <a 
                class="d-block"
                href="#"
                @click="$redirect_reload('/imoveis/1/Aluguel')"
              >
                Aluguel
              </a>
              <a 
                class="d-block"
                href="#"
                @click="$redirect_reload('/imoveis/2/Compra')"
              >
                Compra
              </a>
              <a 
                class="d-block"
                href="#"
                @click="$redirect_reload('/imoveis/3/Investimento')"
              >
                Imóveis para investimento
              </a>
             <!--  <li><router-link to="/servicos">Serviços</router-link></li>     -->
            </ul>
            <ul>
              <li><router-link to="/sobre">Sobre</router-link></li>
              <li><router-link to="/blog">Blog</router-link></li>
              <li><router-link to="/contato">Contato</router-link></li>
            </ul>
          </div>
        </div>
        <!-- Newsletter -->
        <div class="col-md-6 col-lg-6 col-xl-3 footer-widget mt-5 mt-xl-0">
          <div class="newsletter-widget">
            <a target="_blank"
              :href="footerWhatsappLink" 
              class="nav-link whats-footer border-gradient px-4 mb-4" style="color:#333333;"
            >
              <i class="fa fa-whatsapp m-right-5"></i>
              {{ footerWhatsapp }}
            </a>
            <!-- <p>Lorem ipsum dolo sit azmet, consecter dipise consult  elit</p> -->
            <form class="footer-newslatter-form">
              <input 
                type="email" 
                placeholder="Seu E-mail"
                v-model="email"
              >
              <button
                type="button"
                @click="enviarDadosFormulario"
              >
                <i class="fa fa-send"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <a href="https://avancedigital.com.br/" target="_blank">
          <img class="logo_avance" src="/img/logo_avance.png">
        </a>
      </div>
    </div>
  </footer>

</template>

<script>
export default {
  data() {
    return {

      footer: [],
      footer_bg: '',
      footerWhatsapp: '',
      footerWhatsappLink: '',

      contato: [],
      contatoEndereco: '',
      contatoTelefone: '',
      contatoEmail: '',
      contatoEmailRecebe:'',

      socialFacebook: [],
      facebookLink: '',

      socialInstagram: [],
      instagramLink: '',

      socialYoutube: [],
      youtubeLink: '',

      socialTwitter: [],
      twitterLink: '',

      email: '',

      // Objeto Contato      
      Contato: {
        NomeEmpresa: "BRX Imobiliária",
        Assunto: "Newsletter",
        Destinatario: "",
        EmailResposta: "",
        TextoEmail: ""
      }

    }
  },
  created() {


    this.$http
    .get(this.$apiUrl + "/institucional/telas/info%20contato")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.contato = retornoAPI

      this.contatoTelefone = this.contato[0].campoExtra2
      this.contatoEmail = this.contato[0].campoExtra1
      this.contatoEndereco = this.contato[0].descricao3
      this.contatoEmailRecebe = this.contato[0].referencia

    });


    this.$http
    .get(this.$apiUrl + "/institucional/telas/informacoes")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.footer = retornoAPI

      this.footerWhatsapp = this.footer[0].textoBtn1
      this.footerWhatsappLink = this.footer[0].linkBtn1
      // this.footerEndereco = this.footer[0].descricao1
      // this.footerTelefone = this.footer[0].descricao2
      // this.footerEmail = this.footer[0].descricao3
      this.footer_bg = this.$apiUrlIndex + this.footer[0].institucionalImg[0].urlImagem

    });


    this.$http
    .get(this.$apiUrl + "/institucional/telas/facebook")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.socialFacebook = retornoAPI.filter((x) => x.visivel)

      this.facebookLink = this.socialFacebook[0].nome

    });

    this.$http
    .get(this.$apiUrl + "/institucional/telas/instagram")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.socialInstagram = retornoAPI.filter((x) => x.visivel)

      this.instagramLink = this.socialInstagram[0].nome

    });

    this.$http
    .get(this.$apiUrl + "/institucional/telas/youtube")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.socialYoutube = retornoAPI.filter((x) => x.visivel)

      this.youtubeLink = this.socialYoutube[0].nome

    });

    this.$http
    .get(this.$apiUrl + "/institucional/telas/twitter")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.socialTwitter = retornoAPI.filter((x) => x.visivel)

      this.twitterLink = this.socialTwitter[0].nome

    });

  },
  methods: {
    enviarDadosFormulario() {

      //Email que irá receber os dados do formulário preenchido (empresa site)
      // this.Contato.Destinatario = this.contatoEmail
      this.Contato.Destinatario = this.contatoEmailRecebe

      //Email de quem preencheu formulário e enviou email (usuário/cliente)
      this.Contato.EmailResposta = this.email

      // Dados que aparecerão no corpo do email
      this.Contato.TextoEmail = "Email: " + this.email
         
      //validar vazios
      if(this.email == '') {
        this.$mensagem_normal("Preencha o campo com seu email");
      
      }else {

        //SE NAO TIVER ANEXO:
        console.log(this.Contato)

        this.$http.post(this.$apiUrl +"/contato/enviamail", this.Contato)
        .then(response => {
          if(response.ok)
            this.$mensagem_normal("Enviado com sucesso!")
            this.email = ''
        },error => {
           this.$mensagem_normal(error.bodyText)
          this.email = ''
        });

      }
     
    }
  }
}
</script>