<template>

  <div>

    <!-- Hero section -->
    <section
      class="hero-section set-img d-flex align-items-end mt-4"
      :style="{ backgroundImage: 'url(' + imovelTopo_bg + ')' }"
    >
      <div class="container">
        <div class="row">
          <div class="col-xl-6">
            <a :href="imovelTopoLink">
              <span class="pretitle">Código: {{ imovelTopoReferencia }}</span>
              <h1>{{ imovelTopoTitulo }}</h1>
              <div class="box-gradient">
                <p><i class="fa fa-th-large m-right-5"></i>{{ imovelTopoArea }}</p>
                <p><i class="fa fa-bed m-right-5"></i>{{ imovelTopoQuartos }}</p>
                <p><i class="fa fa-shower m-right-5"></i>{{ imovelTopoBanhos }}</p>
              </div>
            <h2>{{ imovelTopoValor }}</h2>
            </a>
          </div>
        </div>
        <div class="row">
          <!-- Filter  -->
          <FiltraImoveis class="hidden-sm hidden-xs" />
        </div>
      </div>
    </section>    
    <!------------------------- Fim Header Home -->
      <FiltraImoveis class="hidden-md hidden-lg hidden-xl" />

    <!-- Destaques -->
    <section 
      v-if="imoveisEmDestaque !=''"
      class="feature-section spad"      
    >
      <div class="container">
        <div class="section-title">
          <h3 style="font-weight: bold;">Imóveis em destaque</h3>
        </div>
        <div class="row justify-content-center">
          <div 
            class="col-lg-4 col-md-6"
            v-for="imovel in imoveisEmDestaque"
            :key="imovel.id"
          >
            <!-- feature -->
            <div class="feature-item">
              <router-link 
                :to="/imovelDetalhe/  + 
              imovel.nome.replaceAll(' ','-').replaceAll('/','').replaceAll('#','') + 
              '/' + imovel.institucionalId"
              >
                <div 
                  v-if="imovel.institucionalImg !=''"
                  class="feature-pic set-bg"
                  :style="{ backgroundImage: 'url(' + $apiUrlIndex + imovel.institucionalImg[0].urlImagem + ')' }" 
                >
                </div>
                <div v-else class="border">
                  <img src="/img/indisponivel.jpg">               
                </div>                                 
                <!-- <div class="sale-notic">Venda</div> -->
              </router-link>
              <div class="feature-text">
                <div class="text-center feature-title">                  
                  <span class="price">{{ imovel.cidade.nomeCidade }}</span>
                  <router-link :to="/imovelDetalhe/  + 
              imovel.nome.replaceAll(' ','-').replaceAll('/','').replaceAll('#','') + 
              '/' + imovel.institucionalId">
                    <h4 style="font-size: 20px;min-height: 70px">{{ imovel.nome }}</h4>
                  </router-link>
                  <h6 class="price">{{ imovel.textoBtn2 }}</h6>                 
                </div>

                <!-- <div class="room-info-warp">
                  <div class="room-info">
                    <div class="rf-left">
                      <p><i class="fa fa-th-large"></i> 1000 m2 </p>
                      <p><i class="fa fa-bed"></i> 03 Quartos</p>
                    </div>
                    <div class="rf-right">
                      <p><i class="fa fa-car"></i> 02 Vagas</p>
                      <p><i class="fa fa-bath"></i> 6 Banheiros</p>
                    </div>	
                  </div>
                </div> -->
                <router-link 
                  :to="/imovelDetalhe/  + 
              imovel.nome.replaceAll(' ','-').replaceAll('/','').replaceAll('#','') + 
              '/' + imovel.institucionalId"
                  class="room-price"
                >
                  <div class="feature-footer">
                    <p v-if="imovel.campoExtra1 !=''"><i class="fa fa-th-large m-right-5"></i>{{ imovel.campoExtra1 }}</p>
                    <p v-if="imovel.campoExtra4 !=''"><i class="fa fa-bed m-right-5"></i>{{ imovel.campoExtra4 }}</p>
                    <p v-if="imovel.campoExtra3 !=''"><i class="fa fa-shower m-right-5"></i>{{ imovel.campoExtra3 }}</p>
                  </div>
                </router-link>
              </div>
            </div>
          </div>   
        </div>
      </div>
    </section>


    <!-- Lançamentos -->
    <section 
      v-if="imoveisLancamento !=''"
      class="feature-section spad-50"
    >
      <div class="container">
        <div class="section-title">
          <h3 style="font-weight: bold;">Lançamentos</h3>
        </div>
        <div class="row justify-content-center">
          <div 
            class="col-lg-4 col-md-6"
            v-for="imovel in imoveisLancamento"
            :key="imovel.id"
          >
            <!-- feature -->
            <div class="feature-item">
              <router-link :to="/imovelDetalhe/  + imovel.nome.replaceAll(' ','-').replaceAll('/','').replaceAll('#','') + '/' + imovel.institucionalId">
                <div 
                  v-if="imovel.institucionalImg !=''"
                  class="feature-pic set-bg"
                  :style="{ backgroundImage: 'url(' + $apiUrlIndex + imovel.institucionalImg[0].urlImagem + ')' }" 
                >
                </div>
                <div v-else class="border">
                  <img src="/img/indisponivel.jpg">               
                </div>                        
                <!-- <div class="sale-notic">Venda</div> -->
              </router-link>
              <div class="feature-text">
                <div class="text-center feature-title">                  
                  <span class="price">{{ imovel.cidade.nomeCidade }}</span>
                  <router-link :to="/imovelDetalhe/  + 
              imovel.nome.replaceAll(' ','-').replaceAll('/','').replaceAll('#','') + 
              '/' + imovel.institucionalId">
                    <h4 style="font-size: 20px;min-height: 70px">{{ imovel.nome }}</h4>
                  </router-link>
                  <h6 class="price">{{ imovel.textoBtn2 }}</h6>                 
                </div>
                <router-link 
                  :to="/imovelDetalhe/  + 
              imovel.nome.replaceAll(' ','-').replaceAll('/','').replaceAll('#','') + 
              '/' + imovel.institucionalId"
                  class="room-price"
                >
                  <div class="feature-footer">
                    <p v-if="imovel.campoExtra1 !=''"><i class="fa fa-th-large m-right-5"></i>{{ imovel.campoExtra1 }}</p>
                    <p v-if="imovel.campoExtra4 !=''"><i class="fa fa-bed m-right-5"></i>{{ imovel.campoExtra4 }}</p>
                    <p v-if="imovel.campoExtra3 !=''"><i class="fa fa-shower m-right-5"></i>{{ imovel.campoExtra3 }}</p>
                  </div>
                </router-link>
              </div>
            </div>
          </div>  
        </div>
      </div>
    </section>

    
    <!--  -->
    <!-- <section class="properties-section spad">
      <div class="container">
        <div class="section-title text-center">
          <h3 style="font-weight: bold;">Imóveis em Destaque</h3>
        </div>
        <div class="row">
          <div class="col-md-6">
            <router-link to="/imovelDetalhe">
              <div 
                class="propertie-item set-bg"
                style="background-image: url(/img/03.jpg);" 
              >
                <div class="sale-notic">Venda</div>
                <div class="propertie-info text-white">
                  <div class="info-warp">
                    <h5>Rua dos Andradas, 2345</h5>
                    <p><i class="fa fa-map-marker"></i> Porto Alegre</p>
                  </div>
                  <div class="price">R$ 1.234,56</div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-md-6">
            <router-link to="/imovelDetalhe">
              <div 
                class="propertie-item set-bg"
                style="background-image: url(/img/03.jpg);" 
              >
                <div class="rent-notic">Aluguel</div>
                <div class="propertie-info text-white">
                  <div class="info-warp">
                    <h5>Rua dos Andradas, 2345</h5>
                    <p><i class="fa fa-map-marker"></i> Porto Alegre</p>
                  </div>
                  <div class="price">R$ 1.234,56</div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-md-6">
            <router-link to="/imovelDetalhe">
              <div 
                class="propertie-item set-bg"
                style="background-image: url(/img/03.jpg);" 
              >
                <div class="sale-notic">Venda</div>
                <div class="propertie-info text-white">
                  <div class="info-warp">
                    <h5>Rua dos Andradas, 2345</h5>
                    <p><i class="fa fa-map-marker"></i> Porto Alegre</p>
                  </div>
                  <div class="price">R$ 1.234,56</div>
                </div>
              </div>
            </router-link>
          </div>  
          <div class="col-md-6">
            <router-link to="/imovelDetalhe">
              <div 
                class="propertie-item set-bg"
                style="background-image: url(/img/03.jpg);" 
              >
                <div class="rent-notic">Aluguel</div>
                <div class="propertie-info text-white">
                  <div class="info-warp">
                    <h5>Rua dos Andradas, 2345</h5>
                    <p><i class="fa fa-map-marker"></i> Porto Alegre</p>
                  </div>
                  <div class="price">R$ 1.234,56</div>
                </div>
              </div>
            </router-link>
          </div>      
        </div>
      </div>
    </section> -->
    <!-- $apiUrlIndex + slide.institucionalImg[0].urlImagem -->

    <!-- Encontre por tipo -->
   <!--  <section class="feature-category-section spad-100">
      <div class="container">
        <div class="section-title text-center">
          <h3>Encontre por Tipo</h3>
        </div>
        <div class="row justify-content-center">
          <div 
            class="card_tipo-item col-lg-3 col-md-6 f-cata"
            v-for="tipo in tiposDeImoveis"
            :key="tipo.id"
          >
            <router-link to="/imoveis">
              <img 
                v-if="tipo.institucionalImg !=''"
                :src="$apiUrlIndex + tipo.institucionalImg[0].urlImagem"
              >
              <img 
                v-else
                src="/img/indisponivel.jpg"
              >
            </router-link>
            <router-link to="/imoveis">{{ tipo.nome }}</router-link>            
          </div>
        </div>
      </div>
    </section> -->

    <!-- Encontre por localidade -->
<!--     <section class="spad-100">
      <div class="container">
        <div class="section-title text-center">
          <h3>Encontre por Localidade</h3>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="row">

             
              <div class="col-12">
                <router-link to="/imoveis">
                  <div 
                    class="loc-item loc-item-small flex-center set-img"
                    style="background-image: url(/img/07.jpg);"
                  >
                        
                    <div class="loc-item-content text-center">
                      <h3>Lorem Ipsum</h3>
                      <p>999 Propriedades</p>
                    </div>
                    
                  </div>
                </router-link>
              </div>
            </div>
            <div class="row">

              
              <div class="col-md-6">
                <router-link to="/imoveis">
                  <div 
                    class="loc-item loc-item-small flex-center set-img"
                    style="background-image: url(/img/07.jpg);"
                  >
                    <div class="loc-item-content text-center">
                      <h3>Lorem Ipsum</h3>
                      <p>118 Propriedades</p>
                    </div>
                  </div>
                </router-link>
              </div>

             
              <div class="col-md-6">
                <router-link to="/imoveis">
                  <div 
                    class="loc-item loc-item-small flex-center set-img"
                    style="background-image: url(/img/07.jpg);"
                  >
                    <div class="loc-item-content text-center">
                      <h3>Lorem Ipsum</h3>
                      <p>999 Propriedades</p>
                    </div>
                  </div>
                </router-link>
              </div>

            </div>
          </div> 
          
          
          <div class="col-lg-6">
            <router-link to="/...">
              <div 
                class="loc-item loc-item-big flex-center set-img"
                style="background-image: url(/img/07.jpg);"
              >               
                <div class="loc-item-content text-center">
                  <h3>Lorem Ipsum</h3>
                  <p>999 Propriedades</p>
                </div>                
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>-->


    <!-- Nossos serviços -->
    <section 
      class="services-section spad set-bg" 
      :style="{ backgroundImage: 'url(' + nossosServicos_bg + ')' }"
    >
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-2 col-lg-6 col-xl-6 text-center mb-5 mb-lg-0">
            <img 
              class="hidden-sm-md" 
              :src="nossosServicosImg"
            >
          </div>
          <div class="col-md-10 col-lg-6 col-xl-5 offset-xl-1 pl-lg-0">
            <div class="section-title text-white">
              <h3>{{ nossosServicosTitulo }}</h3>
            </div>

            <div 
              class="services"
              v-for="item in nossosServicosItens"
              :key="item.id"
            >
              <div class="service-item d-flex align-items-center mb-4">
                <img 
                  v-if="item.institucionalImg !=''"
                  :src="$apiUrlIndex + item.institucionalImg[0].urlImagem"
                >
                <img 
                  v-else
                  src="/img/indisponivel.jpg"
                >
                <div class="service-text">
                  <h5>{{ item.nome }}</h5>
                  <p v-html="item.descricao1"></p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>


    <!-- Blog -->
    <!-- <section class="blog-section spad">
      <div class="container">
        <div class="section-title text-center">
          <h3 style="font-weight: bold;">Blog</h3>			
        </div>
        <div class="row">
          <div class="blog-item col-lg-4 col-md-6 ">
            <img src="img/16.png">
            <h5><router-link to="/blogDetalhe">Lorem ipsum dolor sit amet consectetur amet consectetur</router-link></h5>
            <p>Integer luctus diam ac scerisque consectetur. Vimus dotnetact euismod lacus sit amet. Aenean interdus mid vitae maximus...</p>
            <div class="mt-4">
              <router-link to="/blogDetalhe">
                <span class="blog-item-read">
                  Leia Mais 
                  <i class="fa fa-arrow-right"></i>
                </span>
              </router-link>
            </div>
          </div>         
          <div class="blog-item col-lg-4 col-md-6 ">
            <img src="img/16.png">
            <h5><router-link to="/blogDetalhe">Lorem ipsum dolor sit amet consectetur amet consectetur</router-link></h5>
            <p>Integer luctus diam ac scerisque consectetur. Vimus dotnetact euismod lacus sit amet. Aenean interdus mid vitae maximus...</p>
            <div class="mt-4">
              <router-link to="/blogDetalhe">
                <span class="blog-item-read">
                  Leia Mais 
                  <i class="fa fa-arrow-right"></i>
                </span>
              </router-link>
            </div>
          </div>         
          <div class="blog-item col-lg-4 col-md-6 ">
            <img src="img/16.png">
            <h5><router-link to="/blogDetalhe">Lorem ipsum dolor sit amet consectetur amet consectetur</router-link></h5>
            <p>Integer luctus diam ac scerisque consectetur. Vimus dotnetact euismod lacus sit amet. Aenean interdus mid vitae maximus...</p>
            <div class="mt-4">
              <router-link to="/blogDetalhe">
                <span class="blog-item-read">
                  Leia Mais 
                  <i class="fa fa-arrow-right"></i>
                </span>
              </router-link>
            </div>
          </div>         
        </div>
      </div>
    </section> -->

  </div>
</template>

<script>

import FiltraImoveis from '../shared/FiltraImoveis.vue'
// import Depoimentos from '../shared/Depoimentos.vue'

export default {
  components: {
    FiltraImoveis,
    // Depoimentos
  },
  data() {
    return {

      imovelTopo: [],
      imovelTopoReferencia: '',
      imovelTopoTitulo: '',
      imovelTopoArea: '',
      imovelTopoBanhos: '',
      imovelTopoQuartos: '',
      imovelTopoValor: '',
      imovelTopo_bg: '',
      imovelTopoLink: '',

      imoveisEmDestaque: [],

      imoveisLancamento: [],

      tiposDeImoveis: [],

      localidades: [],

      nossosServicos: [],
      nossosServicosTitulo: '',
      nossosServicos_bg: '',
      nossosServicosImg: '',

      nossosServicosItens: [],
      
    }
  },
   methods: {
      randomNumber(value) { 
        return Math.floor(Math.random() * (value));
      }
  },
  created() {
    this.$http
    .get(this.$apiUrl + "/institucional/telas/imovel%20topo")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.imovelTopo = retornoAPI.filter(x=>x.visivel)
      let _vRecebe = this.imovelTopo[this.randomNumber(this.imovelTopo.length)]
      //console.log(_vRecebe)
      //console.log(this.randomNumber(this.imovelTopo.length))
      this.imovelTopoTitulo = _vRecebe.nome
      this.imovelTopoArea = _vRecebe.campoExtra1
      this.imovelTopoBanhos = _vRecebe.campoExtra3
      this.imovelTopoQuartos = _vRecebe.campoExtra4
      this.imovelTopoValor = _vRecebe.linkBtn2
      this.imovelTopoReferencia = _vRecebe.referencia
      this.imovelTopoLink = _vRecebe.linkBtn1
      this.imovelTopo_bg = this.$apiUrlIndex + _vRecebe.institucionalImg[0].urlImagem

    });

    this.$http
    .get(this.$apiUrl + "/institucional/telas/cad%20imoveis")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.imoveisEmDestaque = retornoAPI.filter((x) => x.visivel && x.destaque).reverse()
      this.imoveisLancamento = retornoAPI.filter((x) => x.visivel && x.destaqueExtra).reverse()

      console.log('this.imoveisLancamento')
      console.log(this.imoveisLancamento)
      
    });


    this.$http
    .get(this.$apiUrl + "/institucional/telas/tipo")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.tiposDeImoveis = retornoAPI.filter((x) => x.visivel)

      this.tiposDeImoveis.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }       
        if (a.ordem < b.ordem) { return -1; }        
        return 0;
      })

    });


    /* this.$http
    .get(this.$apiUrl + "/institucional/telas/localidade")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.localidades = retornoAPI.filter((x) => x.visivel)

      this.localidades.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }       
        if (a.ordem < b.ordem) { return -1; }        
        return 0;
      })

    }); */

    this.$http
    .get(this.$apiUrl + "/institucional/telas/nossos%20servicos%20fundo")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.nossosServicos = retornoAPI

      this.nossosServicosTitulo = this.nossosServicos[0].nome
      this.nossosServicos_bg = this.$apiUrlIndex + this.nossosServicos[0].institucionalImg[0].urlImagem
      this.nossosServicosImg = this.$apiUrlIndex + this.nossosServicos[0].institucionalImg[1].urlImagem
    });


    this.$http
    .get(this.$apiUrl + "/institucional/telas/nossos%20servicos%20itens")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.nossosServicosItens = retornoAPI.filter((x) => x.visivel)

      this.nossosServicosItens.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }       
        if (a.ordem < b.ordem) { return -1; }        
        return 0;
      })

    });

    

  }
  
}

</script>

