<template>

  <div>

    <!-- Hero section Internas -->
    <section
      v-if="topoInternas_bg !=''"
      class="hero-section hero-section-in set-img d-flex align-items-end mt-4"
      :style="{ backgroundImage: 'url(' + topoInternas_bg + ')' }"
    >
      <div class="container mt-4">
        <div class="row mt-4">
          <div class="col text-center">
            <h2>{{ imovelModalidade }}</h2>
          </div>
        </div>
        <div class="row">
          <!-- Filter  -->
          <FiltraImoveis />
        </div>
      </div>
    </section>  

    <!-- v-else -->
    <section
      v-else
      class="hero-section hero-section-in set-img d-flex align-items-end mt-4"
      style="background-color: #ccc;"
    >
      <div class="container mt-4">
        <div class="row">
          <div class="col text-center">
            <h2>{{ imovelModalidade }}</h2>
          </div>
        </div>
        <div class="row">
          <!-- Filter  -->
          <FiltraImoveis />
        </div>
      </div>
    </section>   
    <!------------------------- Fim Header Imóveis -->


    <!--  -->
    <section class="namePrice spad-50">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-lg-8 d-flex align-items-center">
            <h3 class="name mb-5 mb-md-0">{{ imovelTitulo }}</h3>
          </div>
          <div class="col-md-6 col-lg-4 flex-center">
            <div class="price">
              <span>{{ imovelModalidade }}</span>
              <h4>{{ imovelValor }}</h4>
            </div>
          </div>
        </div>
      </div>
    </section>


    <!-- Breadcrumb -->
    <!-- <div class="site-breadcrumb">
      <div class="container">
        <router-link to="/"><i class="fa fa-home"></i>Home </router-link>
        <router-link to="/imoveis"><i class="fa fa-angle-right"></i>Aluguel </router-link>
        <span><i class="fa fa-angle-right"></i> Detalhe do aluguel</span>
      </div>
    </div> -->

    <section class="imagesGallery">
      <div class="container-fluid">
        <div class="row">
          <div class="col text-center px-0">
          <b-carousel id="carousel-1" v-model="slide" :interval="5000" controls indicators fade
            img-width="1024" img-height="480" class="slider-height d-flex align-items-center">
            <!-- Slides with custom text -->
            <b-carousel-slide v-for="slider in imovelDetalhe[0].institucionalImg" :key="slider.Id" :img-src="$apiUrlIndex + slider.urlImagem">
            </b-carousel-slide>
        </b-carousel>
<!--             <img v-if="imovel_bg !=''" :src="imovel_bg"> -->
          </div>
        </div>
        <div class="row align-content-center">
          <div class="images" style="display: inline-flex;overflow: hidden;padding-left: 3px;" v-viewer>
              <img v-for="src in imovelDetalhe[0].institucionalImg" :src="$apiUrlIndex + src.urlImagem" :key="src.institucionalImgId" class="alturaimg"> 
          </div>
        </div>
      </div>
    </section>


    <!-- Page -->
    <section class="page-section mt-5">
      <div class="container">
        
        <div class="row">
          <!-- sidebar -->
          <div class="col-lg-4 sidebar order-2 order-lg-1">
            <div class="util-links">
               <div class="contact-form-card">
                  <h5>Gostaria de mais informações, Agendar um visita ou fazer uma proposta?</h5>
                  <form class="contact-form">
                    <input type="text" placeholder="Seu Nome" v-model="nome">
                     <input type="text" placeholder="Seu Telefone" v-model="telefone">
                    <input type="text" placeholder="Seu E-mail" v-model="email">
                    <textarea placeholder="Comentários" v-model="mensagem"> </textarea>
                    <a href="#" class="nav-item" @click.prevent="enviarDadosFormulario()">Quero Contato</a>
                  </form>
                </div>
            </div>
           <!--  <div class="related-properties single-list-content">
              <h3 class="sl-sp-title">Outros Imóveis</h3>
            </div> -->
          </div>

          <!-- Main -->
          <div class="col-lg-7 offset-lg-1 order-1 order-lg-2 single-list-page">

            <div class="single-list-content">
              <span class="price">Código: {{ imovelCodigo }}</span>
              <h3 class="sl-sp-title">{{ imovelCidade }}/{{ imovelUF }} - Bairro {{ imovelBairro }}</h3>
              <div class="row property-details-list" style="text-align: center;">
                <div class="col-3 col-md-3" style="display: inline-flex;">
                  <p v-if="imovelArea !=''"><i class="fa fa-th-large d-block"></i> {{ imovelArea }}</p>
                </div>

                <div class="col-3 col-md-3">
                  <p v-if="imovelVagas !=''"><i class="fa fa-car d-block"></i> {{ imovelVagas }}</p>
                </div>

                <div class="col-3 col-md-3">
                  <p v-if="imovelBanhos !=''"><i class="fa fa-bath d-block"></i> {{ imovelBanhos }}</p>                  
                </div>
                
                <div class="col-3 col-md-3">                  
                  <p v-if="imovelQuartos !=''"><i class="fa fa-bed d-block"></i> {{ imovelQuartos }}</p>
                </div>
            
                
              </div>
              <h3 class="sl-sp-title">Descrição do empreendimento</h3>
              <div class="description">
                <p v-html="imovelDescricao"></p>
              </div>

              <div v-if="imovelVideo != null">
                <h3 class="sl-sp-title bd-no">Video</h3>
                <div class="perview-video">
                  <iframe 
                    width="100%" 
                    height="420" 
                    :src="imovelVideo" 
                    title="YouTube video player" 
                    frameborder="0" 
                    allowfullscreen
                  >
                  </iframe>
                </div>
              </div>
              
              <h3 class="sl-sp-title bd-no">Localização</h3>
              <div class="pos-map">
                <iframe 
                  :src="'https://www.google.com/maps/embed/v1/place?key=AIzaSyDmXp4J53f9Wt7iHX7dzIn7rHG7yL-EXXo&q=' + imovelCidade + '-' + imovelUF" 
                  width="100%" 
                  height="450px" 
                  frameborder="0" 
                  style="border:0;" 
                  allowfullscreen="" 
                  aria-hidden="false" 
                  tabindex="0">
                </iframe>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </section>


    <!-- CTA -->
    <CTA />

  </div>
</template>

<script>
import 'viewerjs/dist/viewer.css'
import FiltraImoveis from '../shared/FiltraImoveis.vue'
import CTA from '../shared/CTA.vue'
// import Depoimentos from '../shared/Depoimentos.vue'

export default {
  components: {
    FiltraImoveis,
    CTA
  },
  data() {
    return {

      topoInternas: [],
      topoInternas_bg: '',
      
      imovelDetalhe: [],
      idImovelDetalhe: this.$route.params.idImovel,
      imovelModalidade: '',
      imovelCodigo: '',
      imovelCidade: '',
      imovelBairro: '',
      imovelUF: '',
      imovelTitulo: '',
      imovelDescricao: '',
      imovel_bg: '',
      imovelMapa: '',
      imovelArea: '',
      imovelVagas: '',
      imovelBanhos: '',
      imovelQuartos: '',
      imovelVideo: '',
      imovelValor: '',

      imoveisEmDestaque: [],
      contatoEmailRecebe:'',
      nome: '',
			email: '',
      telefone:'',
			mensagem: '',
       // Objeto Contato      
      Contato: {
        NomeEmpresa: "BRX Imobiliária",
        Assunto: "",
        Destinatario: "",
        EmailResposta: "",
        TextoEmail: ""
      }

    }
  },
  created() {

    this.$http
    .get(this.$apiUrl + "/institucional/telas/topo%20imoveis")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.topoInternas = retornoAPI

     if (this.topoInternas[0].institucionalImg!= ''){
        this.topoInternas_bg = this.$apiUrlIndex + this.topoInternas[0].institucionalImg[0].urlImagem
     }
      

    });

    this.$http
    .get(this.$apiUrl + "/institucional/telas/info%20contato")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.contato = retornoAPI
      this.contatoEmailRecebe = this.contato[0].referencia

    });

    this.$http
    .get(this.$apiUrl + "/institucional/telas/cad%20imoveis")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.imovelDetalhe = retornoAPI.filter((x) => x.visivel && x.institucionalId == this.idImovelDetalhe)

      this.imovelModalidade = this.imovelDetalhe[0].segmentoInstNivel1.nomeSegmento

      this.imovelCodigo = this.imovelDetalhe[0].referencia
      this.imovelCidade = this.imovelDetalhe[0].cidade.nomeCidade
      this.imovelUF = this.imovelDetalhe[0].cidade.estado.siglaEstado
      this.imovelBairro = this.imovelDetalhe[0].bairro
      this.imovelTitulo = this.imovelDetalhe[0].nome
      this.imovelDescricao = this.imovelDetalhe[0].descricao2
      this.imovelMapa = this.imovelDetalhe[0].descricao3
      this.imovelArea = this.imovelDetalhe[0].campoExtra1
      this.imovelVagas = this.imovelDetalhe[0].campoExtra2
      this.imovelBanhos = this.imovelDetalhe[0].campoExtra3
      this.imovelQuartos = this.imovelDetalhe[0].campoExtra4
      this.imovelVideo = this.imovelDetalhe[0].textoBtn1
      this.imovelValor = this.imovelDetalhe[0].textoBtn2
      if (this.imovelDetalhe[0].institucionalImg!=''){
         this.imovel_bg = this.$apiUrlIndex + this.imovelDetalhe[0].institucionalImg[0].urlImagem
      }
      

      // this.imoveisEmDestaque = retornoAPI
      
    });

  },
   methods: {
    enviarDadosFormulario() {
      //Email que irá receber os dados do formulário preenchido (empresa site)
      this.Contato.Destinatario = this.contatoEmailRecebe
      //Email de quem preencheu formulário e enviou email (usuário/cliente)
      this.Contato.EmailResposta = this.email
     
      this.Contato.Assunto = "Interesse Imóvel - Cód.: " + this.imovelCodigo 
      // Dados que aparecerão no corpo do email
      this.Contato.TextoEmail = 
        "Nome: " + this.nome + 
        " <br /> E-mail: " + this.email +
        " <br /> Telefone: " + this.telefone +
        " <br /> Imóvel: " + this.imovelCodigo + " - " + this.imovelTitulo +
        " <br /> Descrição: " + this.mensagem 

      //validar vazios
      if(this.nome == '' || this.email == '' || this.telefone == '') {
        this.$mensagem_normal("Preencha todos os campos");
      
      }else {

        //SE NAO TIVER ANEXO:
        // console.log(this.Contato)

        this.$http.post(this.$apiUrl +"/contato/enviamail", this.Contato)
        .then(response => {
          if(response.ok)
            this.$mensagem_normal("Enviado com sucesso!")
            this.nome = ''
            this.email = ''
            this.mensagem = ''
            this.telefone = ''
            this.Contato.Assunto=""
        },error => {
          this.$mensagem_normal(error.bodyText)
          this.nome = ''
          this.email = ''
          this.mensagem = ''
          this.telefone = ''
          this.Contato.Assunto=""
        });
      }
     }
  }
}

</script>

