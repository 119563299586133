<template>
  
  <div class="col-12 section-filter">
      <form action="">
        <div class="row">        
          <div class="col-md-6 col-lg-3">
            <div class="row">
              <div class="col">
                <small>Código</small>
                <input
                  v-model="CodigoImovel"
                  type="text"
                  class="form-control form-control-filter"
                  placeholder="Opcional"
                />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <small>Estado*</small>
                <v-select
                  v-model="Estadoselected"
                  @input="MudaEstado"
                  :options="Estados"
                  :value="Estados.SiglaEstado"
                  single-line
                  label="nomeEstado"
              
                ></v-select>
              </div>
            </div>
            
          </div>
          <div class="col-md-6 col-lg-3">
             <div class="row">
              <div class="col">
                <small>Tipo Negócio*</small>
                <v-select
                  v-model="Tiposelected"
                  @input="MudaTipo"
                  :options="TiposSegmentosnivel1"
                  :value="TiposSegmentosnivel1.segmentoInstNivel1Id"
                  single-line
                  label="nomeSegmento"
                  class="style-chooser w-100"
                >
                </v-select>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <small>Cidade*</small>
                <v-select
                  v-model="Cidadeselected"
                  @input="MudaCidade"
                  :options="Cidades"
                  :value="Cidades.cidadeId"
                  single-line
                  label="nomeCidade"
                  class="style-chooser w-100"
                ></v-select>
              </div>
            </div>
           
            
          </div>
          <div class="col-6 col-md-6 col-lg-3">
              <div class="row">
              <div class="col">
                <small>Tipo Imóvel*</small>
                <v-select
                  v-model="TipoImovelselected"
                  @input="MudaTipoImovel"
                  :options="TiposImovel"
                  :value="TiposImovel.segmentoInstNivel2Id"
                  single-line
                  label="nomeSegmento"
                  class="style-chooser w-100"
                >
                </v-select>
              </div>
            </div>
          <div class="row">
              <div class="col">
                <small>Bairro</small>
                  <v-select
                  v-model="BairroSelected"
                  :options="Bairro"
                  :value="Bairro"
                  single-line
                  label="nome"
                  class="style-chooser w-100"
                >
                </v-select>
             <!--    <input
                  type="text"
                  v-model="Bairro"
                  class="form-control form-control-filter mb-2"
                  placeholder="Opcional"
                /> -->
              </div>
            </div>
            
          </div>
          <div class="col-6 col-md-6 col-lg-3">
            <div class="row">
              <div class="col">
                <small>De</small>
                <money
                  v-model.lazy="valorDe"
                  class="form-control form-control-filter"
                  placeholder="Valor de: "
                  type="text"
                  v-bind="money"
                />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <small>Até</small>
                <money
                  v-model.lazy="valorAte"
                  class="form-control form-control-filter"
                  placeholder="Valor até: "
                  type="text"
                  v-bind="money"
                />
              </div>
            </div>
            
          </div>
        </div>
        <div class="row justify-content-md-center mt-4">
          <div class="col-lg-3 col-12">
                <div class="btn-filter flex-center">
                    <a href="#" @click.prevent="CarregaBuscaRapida()" class="btn-gradient">Pesquisar</a>
                </div>
          </div>
        </div>
      </form>  
  </div>

</template>

<script>
import { VMoney } from "v-money";
export default {
  data() {
    return {
      CodigoImovel: "",
      EstadoId: 0,
      Estados: [],
      Estadoselected: null,
      CidadeId: 0,
      Cidades: [],
      Cidadeselected: null,
      TipoId: 0,
      TiposSegmentosnivel1: [],
      Tiposelected: null,
      TipoImovelId: 0,
      TiposImovel: [],
      TipoImovelselected: null,
      BairroSelected:null,
      Bairro: [],
      ListaBairro:[],
      valorDe: 0,
      valorAte: 0,
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: true,
      },
    };
  },
  directives: { money: VMoney },

  created() {
    // //carrega segmento 1 modalidade (compra ou investimento)
    this.$http
      .get(this.$apiUrl + "/segmentoinstnivel1")
      .then((res) => res.json())
      .then((tiu) => {
        this.TiposSegmentosnivel1 = tiu.filter((x) => x.visivel);
        console.log(this.TiposSegmentosnivel1)
/*          let lista = []
          this.TiposSegmentosnivel1.forEach(element => {
              lista.push(element.nomeSegmento);
          });
           this.TiposSegmentosnivel1 = [...new Set(lista)]; */
      });

    //carrega estados ativos
    this.$http
      .get(this.$apiUrl + "/estados/ativos")
      .then((res) => res.json())
      .then((tiu) => {
        this.Estados = tiu;
      });
  },

  methods: {
    MudaEstado(value) {
      this.EstadoId = value.estadoId;
      this.CidadeId = 0;
      this.Cidades = [];
      this.Cidadeselected = null;
      this.$http
        .get(this.$apiUrl + "/cidades/ativosEstados/" + this.EstadoId)
        .then((res) => res.json())
        .then((tiu) => (this.Cidades = tiu));
    },
    MudaCidade(value) {
      if (value != null) {
        this.CidadeId = value.cidadeId;
        this.Bairro = [];
        this.BairroSelected = null;
        this.$http
        .get(this.$apiUrl + "/institucional/telas/cad%20imoveis")
        .then((res) => res.json())
        .then((retornoAPI) => {
          this.ListaBairro = retornoAPI.filter(x=> x.cidade.cidadeId == this.CidadeId).sort(function (a, b) {
                        if (a.bairro > b.bairro) {
                            return 1;
                        }
                        if (a.bairro < b.bairro) {
                            return -1;
                        }
                        return 0;
                    });
          let listaBairro = []
          this.ListaBairro.forEach(element => {
              listaBairro.push(element.bairro);
          });
           this.Bairro = [...new Set(listaBairro)];
           
        });
      } else {
        this.Bairro = [];
        this.BairroSelected = null;
        this.CidadeId = 0;
      }
      
    },
    MudaTipo(value) {
      if (value != null) {
        this.TipoId = value.segmentoInstNivel1Id;
      } else {
        this.TipoId = 0;
      }
      this.TipoImovelId = 0;
      this.TiposImovel = [];
      this.TipoImovelselected = null;
      //carrega segmento 2 tipo (casa apartamento)
      this.$http
        .get(this.$apiUrl + "/segmentoInstNivel2/nivel1/" + this.TipoId)
        .then((res) => res.json())
        .then((tiu) => {
          this.TiposImovel = tiu;
        });
    },
    MudaTipoImovel(value) {
      if (value != null) {
        this.TipoImovelId = value.segmentoInstNivel2Id;
      } else {
        this.TipoImovelId = 0;
      }
    },
    CarregaBuscaRapida() {
      if (this.CodigoImovel != '') {
        //somente por codigo
         let ObjBusca = [];
            ObjBusca.push({
              codigo: this.CodigoImovel,
              TipoNegocio: null,
              TipoImovel: null,
              ValorDe: null,
              ValorAte: null,
              Estado: null,
              Cidade: null,
              Bairro: null,
            });
            localStorage.setItem("dadosBuscaBRXImobi", JSON.stringify(ObjBusca));
         let vtexto = "";
         vtexto = "Compra";
         this.$redirect_reload("/imoveis/2/" + vtexto);
      } else {
          if (
            this.BairroSelected != null &&
            this.Estadoselected == null &&
            this.Cidadeselected == null
          ) {
            this.$mensagem_normal(
              "Para preencher o bairro é necessário escolher Estado e Cidade."
            );
          } else if (parseFloat(this.valorDe.replaceAll('.','').replaceAll(',','.')) > parseFloat(this.valorAte.replaceAll('.','').replaceAll(',','.'))) {
            this.$mensagem_normal("Valor De deve ser menor que Valor Até.");
          } else if (
            this.BairroSelected != null &&
            this.Estadoselected != null &&
            this.Cidadeselected == null
          ) {
            this.$mensagem_normal(
              "Para preencher o bairro é necessário escolher Estado e Cidade."
            );
          } else if (this.Tiposelected == null) {
            this.$mensagem_normal("Preencher o Tipo de Negócio.");
          } else {
            let ObjBusca = [];
            ObjBusca.push({
              codigo: null,
              TipoNegocio: this.TipoId,
              TipoImovel: this.TipoImovelId,
              ValorDe: this.valorDe,
              ValorAte: this.valorAte,
              Estado: this.Estadoselected,
              Cidade: this.Cidadeselected,
              Bairro: this.BairroSelected,
            });
            localStorage.setItem("dadosBuscaBRXImobi", JSON.stringify(ObjBusca));

            let vtexto = "";
            if (this.TipoId == 2) {
              vtexto = "Compra";
            } else if (this.TipoId == 3) {
              vtexto = "Investimento";
            } else if (this.TipoId == 1) {
              vtexto = "Aluguel";
            } else {
              vtexto = "";
            }
            this.$redirect_reload("/imoveis/" + this.TipoId + "/" + vtexto);
          }
      }
    },
  },
};
</script>