<template>

  <div>

    <!-- Hero section Institucional -->
    <section
      v-if="topoInternas_bg !=''"
      class="hero-section hero-section-inst set-img d-flex align-items-end"
      :style="{ backgroundImage: 'url(' + topoInternas_bg + ')' }"
    >
      <div class="container">
        <div class="row">
          <div class="col text-center m_bottom-50">
            <h2>{{ topoInternasTitulo }}</h2>
          </div>
        </div>
      </div>
    </section> 


    <section
      v-else
      class="hero-section hero-section-inst set-img d-flex align-items-end"
      style="background-color: #ccc;"
    >
      <div class="container">
        <div class="row">
          <div class="col text-center m_bottom-50">
            <h2>{{ topoInternasTitulo }}</h2>
          </div>
        </div>
      </div>
    </section>    
    <!------------------------- Fim Header Institucional -->

    <!-- Breadcrumb -->
    <!-- <div class="site-breadcrumb">
      <div class="container">
        <router-link to="/"><i class="fa fa-home"></i>Home </router-link>
        <span><i class="fa fa-angle-right"></i> Serviços</span>
      </div>
    </div> -->

    <!-- page -->
    <section class="spad-100 single-blog">
      <div class="container">
        <div class="row">
          <div class="col-11 col-md-6 col-lg-5 col-xl-4 mb-5">
            <div id="admin-sidebar" class="col-md-10 p-x-0 p-y-3">
              <ul class="sidenav admin-sidenav list-unstyled">
                <a 
                  class="cursor"
                  v-for="servico in servicos"
                  :key="servico.id"
                  @click="$redirect_reload('/servicos/' + 
                  servico.nome.replaceAll(' ','-').replaceAll('/','') + 
                  '/' + servico.institucionalId)"
                >
                  <li class="text-black">{{ servico.nome }}</li>
                </a>                  
              </ul>
            </div> <!-- /#admin-sidebar -->
          </div>

          <!-- Main -->
          <div class="col-xl-8 singel-blog-content">
            <h3 class="sl-sp-title mb-4">{{ servicoTitulo }}</h3>
            <p v-html="servicoDescricao"></p>
          </div>
        </div>
      </div>
    </section>


    <!-- CTA -->
    <CTA />

  </div>
</template>

<script>

import CTA from '../shared/CTA.vue'

export default {
  components: {
    CTA
  },
  data() {
    return {

      topoInternas: [],
      topoInternasTitulo: '',
      topoInternas_bg: '',

      servicos: [],
      servicoTitulo: '',
      servicoDescricao: ''


    }
  },
  created() {

    this.$http
    .get(this.$apiUrl + "/institucional/telas/servicos%20topo")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.topoInternas = retornoAPI

      this.topoInternasTitulo = this.topoInternas[0].nome
      this.topoInternas_bg = this.$apiUrlIndex + this.topoInternas[0].institucionalImg[0].urlImagem

    });


    this.$http
    .get(this.$apiUrl + "/institucional/telas/cadastro%20servicos")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.servicos = retornoAPI.filter((x) => x.visivel)

      this.servicos.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }       
        if (a.ordem < b.ordem) { return -1; }        
        return 0;
      })

      this.servicoTitulo = this.servicos[0].nome
      this.servicoDescricao = this.servicos[0].descricao1

    });

    this.carregaServico(this.$route.params.idServico)

  },
  methods: {
    carregaServico(idServico) {    
      this.$http
      .get(this.$apiUrl + "/institucional/" + idServico)
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.servico = retornoAPI

        this.servicoTitulo = this.servico.nome
        this.servicoDescricao = this.servico.descricao1
        
          
      });
    }

  }
  
}

</script>

